import type { SnackbarProviderProps } from 'notistack';
import { AlertSnackbar } from './AlertSnackbar';

export const snackbarProviderConfig: Pick<
  SnackbarProviderProps,
  'anchorOrigin' | 'maxSnack' | 'Components' | 'variant' | 'classes'
> = {
  anchorOrigin: {
    horizontal: 'center',
    vertical: 'top',
  },
  classes: {
    root: 'custom-snackbar-root',
  },
  Components: {
    standard: AlertSnackbar,
  },
  maxSnack: 5,
  // matches Mui's theme class states - e.g standardSuccess
  variant: 'standard',
};
