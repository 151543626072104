/* eslint-disable react/no-multi-comp */
import type { ReactElement } from 'react';
import type {
  CardProps,
  ListItemButtonProps,
  ListItemProps,
  StackProps,
  TypographyProps,
} from '@mui/material';
import {
  Card,
  ListItemIcon as MuiListIcon,
  ListItem as MuiListItem,
  ListItemButton as MuiListItemButton,
  Stack,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { getInteractiveProps } from '../links/getInteractiveProps';

const StyledListItemButton = styled(MuiListItemButton)(() => ({
  '&:hover': {
    backgroundColor: 'transparent',
  },
  padding: 0,
  width: '100%',
}));

type PpListItemButton = ListItemButtonProps & {
  href?: HTMLAnchorElement['href'];
  target?: HTMLAnchorElement['target'];
};

type PpListItemCard = {
  withHoverEffect?: boolean;
} & Pick<CardProps, 'children' | 'className' | 'component' | 'elevation' | 'sx' | 'variant'>;

export function ListItemButton({ children, ...props }: PpListItemButton): ReactElement {
  const interactiveProps = getInteractiveProps({
    fallback: 'div',
    href: props.href,
    onClick: props.onClick,
  });
  return (
    <StyledListItemButton
      {...interactiveProps}
      {...props}
      sx={{ ...props.sx, cursor: interactiveProps.cursor }}
    >
      {children}
    </StyledListItemButton>
  );
}

export function ListItem({ children, ...props }: ListItemProps): ReactElement {
  return (
    <MuiListItem disablePadding {...props}>
      {children}
    </MuiListItem>
  );
}

const StyledListItemCard = styled(Card, {
  shouldForwardProp: (prop) => prop !== 'withHoverEffect',
})<PpListItemCard>(({ theme, withHoverEffect = true }) => ({
  ...(withHoverEffect && {
    '&:hover': {
      backgroundColor: theme.palette.grayscale[100],
    },
  }),
  alignItems: 'center',
  display: 'flex',
  width: '100%',
}));

export function ListItemCard({
  children,
  className,
  component,
  elevation,
  sx,
  variant = 'elevation',
  withHoverEffect,
}: PpListItemCard): React.ReactElement {
  return (
    <StyledListItemCard
      raised
      className={className}
      component={component}
      elevation={elevation}
      sx={{ width: '100%', ...sx }}
      variant={variant}
      withHoverEffect={withHoverEffect}
    >
      {children}
    </StyledListItemCard>
  );
}

type PpListItemContent = StackProps & {
  padding?: number;
};
export const ListItemContent = styled(Stack)<PpListItemContent>(({ padding = 2, theme }) => ({
  alignItems: 'center',
  flexDirection: 'row',
  minHeight: theme.spacing(4.5),
  padding: theme.spacing(padding),
  width: '100%',
}));

const StyledListItemStartContent = styled(Stack)({
  flex: 1,
  justifyContent: 'center',
});
export function ListItemStartContent({ ...props }: StackProps): ReactElement {
  return <StyledListItemStartContent data-qa="start-text" {...props} />;
}

const StyledListItemEndContent = styled(Stack)({
  justifyContent: 'center',
  textAlign: 'right',
});
export function ListItemEndContent({ ...props }: StackProps): ReactElement {
  return <StyledListItemEndContent data-qa="end-text" {...props} />;
}

export function ListItemPrimaryText({ children, ...props }: TypographyProps): ReactElement {
  return (
    <Typography variant="paragraphBodySBold" {...props}>
      {children}
    </Typography>
  );
}

export function ListItemSecondaryText({ children, ...props }: TypographyProps): ReactElement {
  return (
    <Typography color="text.secondary" variant="paragraphBodyS" {...props}>
      {children}
    </Typography>
  );
}

export const ListItemIcon = MuiListIcon;
