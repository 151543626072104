import React, { Fragment, useCallback } from 'react';
import { Dialog } from '@mui/material';
import { CloseButton } from '../buttons/CloseButton';
import type { TpDialog } from './DialogsProvider';
import { LowDialog } from './LowDialog';
import { useDialogs } from './useDialogs';

export function Dialogs(): React.ReactElement | null {
  const { closeDialog, dialogs, removeDialog } = useDialogs();

  const onClose = useCallback(
    (currentDialog: TpDialog) => {
      if (currentDialog.onDismiss) {
        currentDialog.onDismiss();
      }
      closeDialog(currentDialog.key);
    },
    [closeDialog],
  );

  return (
    <Fragment>
      {Object.keys(dialogs).map((key) => {
        const currentDialog = dialogs[key];
        const renderLowDialog = currentDialog.low;

        const Component = renderLowDialog ? LowDialog : Dialog;

        const dataQa = renderLowDialog
          ? { dataQa: currentDialog.key }
          : { 'data-qa': `${currentDialog.key}-dialog` };

        return (
          <Component
            {...currentDialog.DialogProps}
            {...dataQa}
            key={currentDialog.key}
            open={currentDialog.open}
            TransitionProps={{ onExited: (): void => removeDialog(currentDialog.key) }}
            onClose={currentDialog.allowDismiss ? (): void => onClose(currentDialog) : undefined}
          >
            {!currentDialog.low && currentDialog.allowDismiss && (
              <CloseButton
                data-qa={`${currentDialog.key}-close`}
                onClick={(): void => onClose(currentDialog)}
              />
            )}
            {currentDialog.Content}
          </Component>
        );
      })}
    </Fragment>
  );
}
